import React from 'react';
import styled from 'styled-components'
import myLikeness from '../../media/ChrisAvatar.png'

const FlipImageContainer = styled.div`
    background-color: transparent;
    perspective: 1000px;
    .inner {
        border-radius: 8px;
        overflow: hidden;
        position: relative;
        width: 55px;
        height: 55px;
        text-align: center;
        transition: transform 0.8s;
        transform-style: preserve-3d;
        .front {
            position: absolute;
            width: 100%;
            height: 100%;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
        }
    }
    &:hover .inner {
        transform: rotateY(180deg);
    }
    img {
        height: 100%;
        width: 100%;
        overflow: hidden;
    }
`

const FlipImage = () => {
    return (
        <FlipImageContainer>
            <div className="inner">
                <div className="front">
                    <img src={myLikeness} alt="Chris Shannon" />
                </div>
            </div>
        </FlipImageContainer>
    )
}

export default FlipImage