import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'
import fire from '../../media/fire_refine.jpg'
import trail from '../../media/trails_assess.png'
import photo from '../../media/add_photo.png'
import park from '../../media/virtual_park.png'
import solar from '../../media/planetsClip.jpg'
import chart from '../../media/chart3Dclip.png'
import banner from '../../media/ubPortMed.jpg'

const CardContainer = styled.li`
  .card-image {
    border: 1px solid #e7e7e7;
    border-radius: 8px 8px 0 0;
  }
  .max-image-container {
    height: 0;
    width: 0;
  }
  @media screen and (min-width: 800px) {
    .max-image-container {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background-color: rgba(225, 225, 225, 0.75);
      display: grid;
      justify-items: center;
      align-items: center;

      button {
        background-color: transparent;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      img {
        width: 75%;
      }
      img.img-small {
        max-width: 600px;
      }
    }
  }
  
`

interface PortfolioCardProps {
    featureImage: any
    featureImageSmall: boolean
    title: string
    description: string
    learnMore: string
    altText: string
    titleLevel?: "button" | "caption" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "inherit" | "subtitle1" | "subtitle2" | "body1" | "body2" | "overline" | undefined
    position: string
}

const PortfolioCard = ({ featureImage, featureImageSmall, title, description, learnMore, altText, titleLevel, position }: PortfolioCardProps) => {
  const [image, setImage] = useState<any>(null)
  const [imageMax, setImageMax] = useState<boolean>(false)

  useEffect(() => {
    if (featureImage) {
      if (featureImage === 'fire') {
        setImage(fire)
      } else if (featureImage === 'trail') {
        setImage(trail)
      } else if (featureImage === 'photo') {
        setImage(photo)
      } else if (featureImage === 'park') {
        setImage(park)
      } else if (featureImage === 'solar') {
        setImage(solar)
      } else if (featureImage === 'chart') {
        setImage(chart)
      } else if (featureImage === 'banner') {
        setImage(banner)
      }
    }
  }, [featureImage])

  return (
    <CardContainer>
      <Card sx={{ maxWidth: 345 }} component="figure">
        <CardMedia
          className="card-image"
          component="img"
          height="140"
          image={image}
          alt={altText}
          onClick={() => setImageMax(!imageMax)}
        />
        <CardContent component="figcaption">
          <Typography gutterBottom variant={titleLevel}>
            {title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {description}
          </Typography>
          <p>
            <br />
            <span>Position: {position}</span>
          </p>
        </CardContent>
        <CardActions>
          {learnMore && <a href={learnMore} target="_blank" rel="noreferrer">Learn More<span className='sr-only'> about the {title} project.</span></a>}
        </CardActions>
      </Card>
      {imageMax && (
        <div role="dialog" className="max-image-container">
          <img className={featureImageSmall ? 'img-small' : ''} src={image} alt={altText} />
          <button type="button" aria-label="Tap this button to close image" onClick={() => setImageMax(!imageMax)}></button>
        </div>
      )}
    </CardContainer>
  );
}

PortfolioCard.defaultProps = {
  titleLevel: "h3"
}

export default PortfolioCard