import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import ChrisShannonResume from '../../media/ChrisShannonResume.pdf'
import DownloadIcon from '@mui/icons-material/Download'

const ContentToggleContainer = styled.nav`
    padding: 15px 30px;
    h3 + button {
        margin-top: 15px;
    }
    button,
    .dl-resume {
        width: 100%;
        padding: 8px 15px;
        text-align: center;
        background-color: ${props => props.theme.colors.white};
        color: #444444;
        margin-bottom: 15px;
        cursor: pointer;
        font-size: ${props => props.theme.fontSizes[4]};
        font-family: ${props => props.theme.fontFamilies.ui};
        border-radius: 8px;
    }
    button.selected,
    button.selected:hover,
    button.selected:focus {
        box-shadow: 3px 3px 0 ${props => props.theme.colors.shadow};
        background-color: ${props => props.theme.colors.white};
        outline: none;
    }
    button:hover {
        box-shadow: -3px -3px 0 ${props => props.theme.colors.shadow};
        color: ${props => props.theme.colors.black};
    }
    button:focus,
    .dl-resume:focus {
        outline: 1px solid #32a1ce;
    }
    .dl-resume:hover {
        background-color: ${props => props.theme.colors.blue};
        color: ${props => props.theme.colors.white};
    }
    .dl-resume {
        align-items: center;
        display: flex;
        justify-content: center;
        margin-bottom: 0 !important;
    }
`

interface ContentToggleProps {
    updateView: (arg0: string) => void
    viewList: string[]
}

const ContentToggle = ({ updateView, viewList }: ContentToggleProps) => {
    const [selectedButton, setSelectedButton] = useState<string>(viewList[0])

    useEffect(() => {
        updateView(selectedButton)
    }, [selectedButton])

    return (
        <ContentToggleContainer>
            <h2 className="sr-only">Content navigation</h2>
            {viewList.map((item: string) => {
                return (
                    <button className={item === selectedButton ? ' selected' : ''} type="button" key={`nav-${item}`} onClick={() => setSelectedButton(item)}>{item}</button>
                )
            })}
            <a className="dl-resume" href={ChrisShannonResume} download><DownloadIcon /><span>Resume</span></a>
        </ContentToggleContainer>
    )
}

export default ContentToggle