import React from 'react';
import styled from 'styled-components'
import { ReactSVG } from 'react-svg'
import LinkedInSVG from '../../media/linkedin.svg'
import EmailSVG from '../../media/email.svg'


const AddressContainer = styled.section`
    display: grid;
    grid-template-columns: min-content min-content;
    padding: 15px 0;
    justify-content: center;
    h3 {
        font-size: ${props => props.theme.fontSizes[4]};
        font-family: ${props => props.theme.fontFamilies.title};
    }
    address {
        display: grid;
        grid-template-columns: min-content min-content;
        align-items: center;
        column-gap: 10px;
        font-size: ${props => props.theme.fontSizes[1]};
        font-family: ${props => props.theme.fontFamilies.body};
        a {
            display: block;
            width: 30px;
            height: 30px;
            svg {
                border-radius: 5px;
            }
        }
        a:first-child {
            width: 38px;
            height: 38px;
        }
    }
`

const Address = () => {
    return (
        <AddressContainer>
            <h3 className="sr-only">Contact information</h3>
            <address>
                <a href="mailto:chrishshannon@gmail.com">
                    <span className="sr-only">chrishshannon@gmail.com</span>
                    <ReactSVG src={EmailSVG} />
                </a>
                <a href="https://www.linkedin.com/in/chris-h-shannon/" target="_blank" rel="noreferrer">
                    <span className="sr-only">https://www.linkedin.com/in/chris-h-shannon/ (LinkedIn)</span>
                    <ReactSVG src={LinkedInSVG} />
                </a>
            </address>
        </AddressContainer>
    )
}

export default Address