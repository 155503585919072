import React, { useState } from 'react';
import styled, { ThemeProvider } from 'styled-components'
import theme from './styles/theme'
import HeaderLogo from './components/Header/HeaderLogo'
import Summary from './components/Content/Summary'
import Experience from './components/Content/Experience'
import Education from './components/Content/Education'
import Portfolio from './components/Content/Portfolio'
import Lesson from './components/Content/Lesson'
import ContentToggle from './components/Content/ContentToggle'
import Address from './components/page/Address'

const AppContainer = styled.section`
  display: grid;
  grid-template-rows: min-content auto min-content;
  height: 100vh;
  .main-content {
    width: 100%;
    display: grid;
    grid-template-columns: min-content auto;
    .content-toggle {
      display: grid;
      border-right: 1px solid ${props => props.theme.colors.blue};
      background-color: ${props => props.theme.colors.gray};
    }
    .content-view {
      padding: 30px 30px 100px;
    }
  }
  footer {
    background-color: ${props => props.theme.colors.blue};
    color: ${props => props.theme.colors.white};
    position: sticky;
    bottom: 0;
    left: 0;
  }
  @media (max-width: 600px) {
    .main-content {
      display: block;
      .content-toggle {
        border-right-style: none;
        border-bottom: 1px solid ${props => props.theme.colors.blue};
      }
    }
  }
`

function App() {
  const views = ['Summary', 'Experience', 'Education', 'Portfolio']
  const [view, setView] = useState<string>(views[0])

  return (
    <ThemeProvider theme={theme}>
      <AppContainer>
        <HeaderLogo />
        <main className="main-content">
          <div className="content-toggle">
            <ContentToggle viewList={views} updateView={(currentView: string) => setView(currentView)} />
          </div>
          <div className="content-view">
            {view === 'Summary' && <Summary />}
            {view === 'Experience' && <Experience />}
            {view === 'Education' && <Education />}
            {view === 'Portfolio' && <Portfolio />}
          </div>
        </main>
        <footer>
          <Address />
        </footer>
      </AppContainer>
    </ThemeProvider>
  );
}

export default App;
