import React from 'react';
import styled from 'styled-components'
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot, TimelineOppositeContent } from '@mui/lab'

import experience from '../../data/experience.json'
import { ExperienceInfo } from '../../data/models';


const SummaryContainer = styled.article`
    background-color: ${props => props.theme.colors.white};
    h2 {
        font-size: ${props => props.theme.fontSizes[4]};
        font-family: ${props => props.theme.fontFamilies.title};
        font-weight: bold;
        color: #000c14;
    }
    p {
        font-size: ${props => props.theme.fontSizes[2]};
        font-family: ${props => props.theme.fontFamilies.body};
        margin-top: 15px;
        padding-bottom: 15px;
    }
    p.org-content {
        display: grid;
        font-weight: bold;
    }
`

const Summary = () => {
    return (
        <SummaryContainer>
            <h2>Summary</h2>
            <p>Experienced software developer looking for an opportunity to
            improve the lives of other people. I love helping others and can provide senior
            software development skills, mentoring, leadership, and training to ensure our team will be successful.</p>
            <Timeline position="alternate">
                {experience.data.map((item: ExperienceInfo, ind: number) => {
                    return (
                        <TimelineItem key={`${item.title}_${ind}_tl`}>
                            <TimelineOppositeContent color="#000c14">
                                <p className='org-content'>
                                    <span>{item.organization}</span>
                                    <span>{item.title}</span>
                                    <span>{item.employed}</span>
                                </p>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                            <TimelineDot />
                            <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent color="#745702">
                                <p className='time-skills'>{item.skills.join(', ')}</p>
                            </TimelineContent>
                        </TimelineItem>
                    )
                })}
            </Timeline>
        </SummaryContainer>
    )
}

export default Summary