import React from 'react';
import styled from 'styled-components'
import experience from '../../data/experience.json'
import { ExperienceInfo } from '../../data/models'

const ExperienceContainer = styled.article`
    background-color: ${props => props.theme.colors.white};
    h2 {
        font-size: ${props => props.theme.fontSizes[4]};
        font-family: ${props => props.theme.fontFamilies.title};
        font-weight: bold;
        color: #000c14;
    } 
`

const Card = styled.div`
    border-bottom: 1px solid ${props => props.theme.colors.blue};
    display: flex;
    flex-direction: column;
    color: #000c14;
    padding: 20px;
    width: 100%;
    .split {
        display: flex;
        justify-content: space-between;
    }
    .organization {
        font-size: ${props => props.theme.fontSizes[3]};
        font-family: ${props => props.theme.fontFamilies.title};
        padding-bottom: 10px;
    }
    .title,
    .employed,
    .location {
        font-size: ${props => props.theme.fontSizes[2]};
        font-family: ${props => props.theme.fontFamilies.ui};
        padding-bottom: 8px;
    }
    .title {
        font-style: italic;
        font-weight: bold;
    }
    .employed {
        padding-left: 8px;
    }
    .location {
        color: #2e2e2e;
    }
    .accomplishments {
        list-style-type: disc;;
        padding-left: 20px;
        li {
            font-size: ${props => props.theme.fontSizes[1]};
            font-family: ${props => props.theme.fontFamilies.body};
        }
    }
    &:last-child {
        border-bottom-style: none;
    }
    @media (max-width: 600px) {
        .split {
            flex-wrap: wrap;
            .employed {
                padding-left: 0;
                flex-basis: 100%;
            }
        }
    }

`

const Experience = () => {
    const { data } = experience
    return (
        <ExperienceContainer>
            <h2>Experience</h2>
            {data.map((item: ExperienceInfo, ind: number) => {
                const { organization, title, employed, location, accomplishments } = item
                return (
                    <Card key={`${organization}_${ind}_exp`}>
                        <p className="split"><span className="organization">{organization}</span><span className="employed">{employed}</span></p>
                        <p className="title">{title}</p>
                        <p className="location">{location}</p>
                        <ul className="accomplishments">
                            {accomplishments.map((acc: string, index: number) => <li key={`list_${index}_item`}>{acc}</li>)}
                        </ul>
                    </Card>
                )
            })}
        </ExperienceContainer>
    )
}

export default Experience