import { DefaultTheme } from "styled-components"

const theme: DefaultTheme = {
    fontFamilies: {
        title: 'Raleway, Verdana, sans-serif',
        body: '"Open Sans", sans-serif',
        ui: 'Raleway, Verdana, sans-serif',
    },
    fontSizes: ['0.75rem', '0.875rem', '1rem', '1.25rem', '1.5rem', '1.75rem', '2rem', '2.25rem', '2.5rem'],
    colors: {
        white: '#ffffff',
        black: '#232323',
        blue: '#1d2e36',
        gray: '#dddddd',
        shadow: '#999999'
    },
    srOnly: 'clip: rect(0, 0, 0); clip-path: inset(50%); height: 1px; overflow: hidden; position: absolute; white-space: nowrap; width: 1px;',
}

export default theme