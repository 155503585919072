import React from 'react';
import styled from 'styled-components'

const EducationContainer = styled.article`
    background-color: ${props => props.theme.colors.white};
    h2 {
        font-size: ${props => props.theme.fontSizes[4]};
        font-family: ${props => props.theme.fontFamilies.title};
        font-weight: bold;
        color: #000c14;
    }
    ul {
        padding-left: 15px;
        li {
            margin-bottom: 20px;
            span {
                font-size: ${props => props.theme.fontSizes[2]};
                font-family: ${props => props.theme.fontFamilies.body};
                display: block;
            }
            span:first-child {
                font-size: ${props => props.theme.fontSizes[3]};
                font-family: ${props => props.theme.fontFamilies.title};
                margin-bottom: 5px;
                font-weight: bold;
            }
            span:nth-child(2) {
                font-style: italic;
            }
        }
        li:first-child {
            margin-top: 20px;
        }
    }
`

const Education = () => {
    return (
        <EducationContainer>
            <h2>Education</h2>
            <ul>
                <li>
                    <span>Ashford University</span>
                    <span>Clinton, IA</span>
                    <span>Master of Arts - MA, Teaching and Learning with Technology (2010)</span>
                </li>
                <li>
                    <span>DeVry University</span>
                    <span>Kansas City, MO</span>
                    <span>Bachelor's degree, Technical Management - Multimedia emphasis (2009)</span>
                </li>
                <li>
                    <span>ITT Technical Institute</span>
                    <span>San Antonio, TX</span>
                    <span>Associate's degree, Computer-aided Design and Drafting (2007)</span>
                </li>
                <li>
                    <span>Community College of the Air Force</span>
                    <span>Keesler AFB, Biloxi, MS</span>
                    <span>Satellite Communications Technician Certification (1993)</span>
                </li>
            </ul>
        </EducationContainer>
    )
}

export default Education