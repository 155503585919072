import React from 'react';
import styled from 'styled-components'
import FlipImage from '../Animations/FlipImage'

const HeaderLogoContainer = styled.header`
    background-color: ${props => props.theme.colors.blue};
    color: ${props => props.theme.colors.white};
    width: 100%;
    padding: 15px 0;
    position: sticky;
    z-index: 100;
    top: 0;
    left: 0;
    .header {
        display: flex;
        justify-content: center;
        .content {
            padding-left: 30px;
            h2 {
            font-size: ${props => props.theme.fontSizes[5]};
            font-family: ${props => props.theme.fontFamilies.title};
            }
            p {
                display: inline-block;
                font-size: ${props => props.theme.fontSizes[2]};
                font-family: ${props => props.theme.fontFamilies.title};
                margin-top: 0;
                padding: 0;
            }
        }
    }
    
`

const HeaderLogo = () => {
    return (
        <HeaderLogoContainer>
            <div className="header">
                <FlipImage />
                <div className="content">
                    <h2>Chris Shannon</h2>
                    <p>Senior Software Engineer</p>
                </div>
            </div>
        </HeaderLogoContainer>
    )
}

export default HeaderLogo