import React from 'react'
import styled from 'styled-components'
import experience from '../../data/experience.json'
import { Project } from '../../data/models'
import PortfolioCard from '../page/PortfolioCard'

const PortfolioContainer = styled.article`
  background-color: ${(props) => props.theme.colors.white};
  h2 {
    font-size: ${(props) => props.theme.fontSizes[4]};
    font-family: ${(props) => props.theme.fontFamilies.title};
    font-weight: bold;
    color: #000c14;
    }
  .list-wrapper {
    padding: 15px;
    font-family: ${(props) => props.theme.fontFamilies.body};
    h3 {
        font-size: ${(props) => props.theme.fontSizes[3]};
        font-family: ${(props) => props.theme.fontFamilies.title};
        font-weight: bold;
        margin-bottom: 10px;
    }
    ul {
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap;
      li {
        margin-bottom: 20px;
        margin-right: 20px;
      }
    }
    @media (max-width: 600px) {
      ul {
        flex-direction: column;
        li {
          margin-right: 0;
          figure {
            max-width: 100%;
          }
        }
    }
    }
    a:focus {
        outline: 1px solid #32a1ce;
    }
  }
`

const Portfolio = () => {
  return (
    <PortfolioContainer>
      <h2>Portfolio</h2>
      <div className="list-wrapper">
        <ul>
          {experience.data.map((item: any) => {
            if (item.projects && item.projects.length) {
              return item.projects.map((project: Project) => {
                return (
                <PortfolioCard
                  key={project.title}
                  featureImage={project.featureImage}
                  featureImageSmall={project.featureImageSmall}
                  title={project.title} 
                  description={project.description} 
                  learnMore={project.learnMore} 
                  altText={project.altText} 
                  position={project.position} 
                />
              )
              })
              
            }
            return false
          })}
        </ul>
      </div>
    </PortfolioContainer>
  );
};

export default Portfolio
